import { useState } from "react";
import { Link } from "react-router-dom";
import '../css/Request.css';

interface CustomWindow extends Window {
    submitted?: boolean;
}

const Request = () => {
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = () => {
        (window as CustomWindow).submitted = true;
    };

    const pageTransition = () => {
        if (submitted) {
            window.location.assign('/Sendrequest');
        }
    };

    return (
        <div>
            <h2>お申し込み</h2>
            <section>
                <form
                    action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSdby08xZzoq3oKR820z9j3N3LCHExzQw5Hhx6oMeIvwpBCnLg/formResponse" 
                    method="post"
                    target="hidden_iframe"
                    onSubmit={() => {
                        handleSubmit();
                        setSubmitted(true);
                    }}
                >
                    お名前(会社名)<br />
                    <input type="text" name="entry.1537201979" /><br />
                    <br />
                    メールアドレス<br />
                    <input type="text" name="entry.969214717" /><br />
                    <br />
                    電話番号<br />
                    <input type="text" name="entry.1857536796" /><br />
                    <br />
                    ご検討中のプラン<br />
                    <textarea name="entry.897283442" rows={3} cols={50}></textarea>
                    <br /><br />
                    {/* 身分証明書(顔付き)<br />
                    <input type="file" name="entry.825670156" /><br />
                    <br /> */}
                    <div className="form-group centered-link">
                        <Link to="/Terms" target="_blank" rel="noopener noreferrer">利用規約</Link>
                    </div>

                    <div className="form-group centered-link">
                        <label>
                            <input
                                type="checkbox"
                                onChange={() => setSubmitted(!submitted)}
                                required
                            />

                            利用規約に同意する
                        </label>
                    </div>
                    <button type="submit">送信</button>
                </form>
            </section>
            <iframe name="hidden_iframe" title="Hidden Form Submission" style={{ display: 'none' }} onLoad={pageTransition}></iframe>

        </div>
    );
}

export default Request;