import { useState } from 'react';
import '../css/Contact.css';

interface CustomWindow extends Window {
  submitted?: boolean;
}

const Contact = () => {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = () => {
    (window as CustomWindow).submitted = true;
  };

  const pageTransition = () => {
    if (submitted) {
      window.location.assign('/Sendform');
    }
  };

  return (
    <div>
      <h2>お問い合わせ</h2>
      <section>
        <form
          action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSe1WSzQYLa0HCKuoMoRlZVH7DfA8iQ0sk837Us8FvV-OieIqA/formResponse"
          method="post"
          target="hidden_iframe"
          onSubmit={() => {
            handleSubmit();
            setSubmitted(true);
          }}
        >
          お名前(会社名)<br />
          <input type="text" name="entry.729189198" /><br />
          <br />
          メールアドレス<br />
          <input type="text" name="entry.2118350840" /><br />
          <br />
          メッセージ<br />
          <textarea name="entry.8762950" rows={3} cols={50}></textarea>
          <br /><br />
          <button type="submit">送信</button>
        </form>
      </section>
      <iframe name="hidden_iframe" title="Hidden Form Submission" style={{ display: 'none' }} onLoad={pageTransition}></iframe>

    </div>
  );
}

export default Contact;
