import { useState } from "react"
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";
import Home from "./pages/Home";
import Service from "./pages/Service";
import Price from "./pages/Price";
import Trainer from "./pages/Trainer";
import SirVoice from "./pages/SirVoice";
import Request from "./pages/Request";
import Contact from "./pages/Contact";
import Sendform from "./pages/Sendform";
import Sendrequest from "./pages/Sendrequest";
import Terms from "./pages/Terms";
import "./App.css";

const App = () => {

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <BrowserRouter>
      <div>
        <header>
          <Link to="/" className="link-style">
            <h1 className="rolling-header">NET-FIT</h1>
          </Link>

         <button
            className={`menu-button ${isMenuOpen ? "close" : ""}`}
            onClick={toggleMenu}
          >
            {isMenuOpen ? "✕" : "☰"}
          </button>
          <nav className={`nav-menu ${isMenuOpen ? "show" : ""}`}>
            <ul>
              <li><a href="/">ホーム</a></li>
              <li><a href="/Service">サービス</a></li>
              <li><a href="/Price">料金</a></li>
              <li><a href="/Trainer">トレーナー</a></li>
              <li><a href="/SirVoice">お客様の声</a></li>
              <li><a href="/Request">申し込み</a></li>
              <li><a href="/Contact">問い合わせ</a></li>
            </ul>
          </nav>
        </header>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Service" element={<Service />} />
          <Route path="/Price" element={<Price />} />
          <Route path="/Trainer" element={<Trainer />} />
          <Route path="/SirVoice" element={<SirVoice />} />
          <Route path="/Request" element={<Request />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/Sendform" element={<Sendform />} />
          <Route path="/Sendrequest" element={<Sendrequest />} />
          <Route path="/Terms" element={<Terms />} />
        </Routes>

      <footer className="footer">
        <div className="footer-content">
          <div className="left-content">
            <nav>
              <ul>
                <li><a href="/">ホーム</a></li>
                <li><a href="/Service">サービス</a></li>
                <li><a href="/Price">料金</a></li>
                <li><a href="/Trainer">トレーナー</a></li>
                <li><a href="/SirVoice">お客様の声</a></li>
                <li><a href="/Request">申し込み</a></li>
                <li><a href="/Contact">問い合わせ</a></li>
              </ul>
            </nav>
          </div>
          <div className="right-content">
            <p>© 2023 NET-FIT. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
    </BrowserRouter>
  );
};

export default App;