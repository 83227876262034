import React, { useState, useEffect } from 'react';
import '../css/SirVoice.css';
import satomi from '../images/sirs/beautiful.jpg';
import kazuo from '../images/sirs/careerwoman.jpg';
import Naoki from '../images/sirs/family.jpg';
import jucy from '../images/sirs/japanmen.jpg';
import hiroto from '../images/sirs/wedding.jpg';
import hina from '../images/sirs/writewoman.jpg';

const SirVoice = () => {
  const trainers = [
    {
      name: "S.A様",
      qualification: "-7kg",
      achievements: "-5%",
      experience: "4ヶ月",
      personality: "もっと早く受ければよかった！",
      image: satomi,
    },
    {
      name: "T.A様",
      qualification: "-5kg",
      achievements: "非公開",
      experience: "4ヶ月",
      personality: "在宅勤務で太ってしまったので受けました。",
      image: kazuo,
    },
    {
      name: "I家 様",
      qualification: "最大-9kg",
      achievements: "最大-8%",
      experience: "2ヶ月",
      personality: "家族みんなで受けました。とても楽しかったです！",
      image: Naoki,
    },
    {
      name: "J.H様",
      qualification: "-7kg",
      achievements: "-5%",
      experience: "4ヶ月",
      personality: "とても楽しかったです。また受けたいです。",
      image: jucy,
    },
    {
      name: "R.K様",
      qualification: "-5kg",
      achievements: "-3%",
      experience: "2ヶ月",
      personality: "結婚式前に5kg落とすことができました。",
      image: hiroto,
    },
    {
      name: "E.A様",
      qualification: "-3kg",
      achievements: "-2%",
      experience: "1ヶ月",
      personality: "指導して頂いて痩せることができました。",
      image: hina,
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const changeSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % trainers.length);
  };

  useEffect(() => {
    const interval = setInterval(changeSlide, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <h2>お客様の声</h2>
      <div className="trainer-container">
        <br />

        <div id="pickup" className="wrapper">
          {trainers.map((trainer, index) => (
            <article key={index} style={{ display: index === currentSlide ? 'block' : 'none' }}>
              <img src={trainer.image} alt={trainer.name} className="article-image" />
              <p className="article-text">
                名前 : {trainer.name}<br />
                体重 : {trainer.qualification}<br />
                体脂肪率 : {trainer.achievements}<br />
                契約機関 : {trainer.experience}<br />
                感想 : {trainer.personality}</p><br />
            </article>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SirVoice;
