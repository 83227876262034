import '../css/Home.css';
import women from '../images/home/women.jpg';
import group from '../images/home/group.jpg';

const Home = () => (
    <div>
        <section className="wrapper">
            <div className="container">
                <div className="content">
                    <div className="content-item">
                        <img src={women} alt="Sample 1" className="image" />
                    </div>
                    <div className="content-item">
                        <div className="text">
                            <h4 className="heading">NET-FITについて</h4>
                            <h4 className="heading">健康/フィットネスに関するお悩みを解決します。</h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="wrapper reverse">
            <div className="container">
                <div className="content">
                    <div className="content-item">
                        <img src={group} alt="Sample 2" className="image" />
                    </div>
                    <div className="content-item">
                        <div className="text">
                            <h4 className="heading">NET-FITのトレーナー</h4>
                            <h4 className="heading">資格/競技実績を持ち、コミュニケーション能力の高い集団です。</h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
);

export default Home;
