import '../css/Service.css';
import man from '../images/service/man.jpg';
import pcwomen from '../images/service/pcwomen.jpg';
import food from '../images/service/food.jpg';
import iphone from '../images/service/iphone.jpg';

const Service = () => (
    <div>
        <section className="wrapper">
            <div className="container">
                <div className="content">
                    <div className="content-item">
                        <img src={man} alt="Sample 1" className="image" />
                    </div>
                    <div className="content-item">
                        <div className="text">
                            <h4 className="heading">オンラインセッション</h4>
                            <h4 className="heading">スマホ/PC/タブレットを用いてレッスンを受けていただきます。</h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="wrapper reverse">
            <div className="container">
                <div className="content">
                    <div className="content-item">
                        <img src={pcwomen} alt="Sample 2" className="image" />
                    </div>
                    <div className="content-item">
                        <div className="text">
                            <h4 className="heading">レッスン教材</h4>
                            <h4 className="heading">動画レッスン/テキストレッスンをご提供しています。</h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="wrapper">
            <div className="container">
                <div className="content">
                    <div className="content-item">
                        <img src={food} alt="Sample 1" className="image" />
                    </div>
                    <div className="content-item">
                        <div className="text">
                            <h4 className="heading">食事指導</h4>
                            <h4 className="heading">カロリー計算/ケトジェニックダイエットなどを用いて指導します。</h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="wrapper reverse">
            <div className="container">
                <div className="content">
                    <div className="content-item">
                        <img src={iphone} alt="Sample 2" className="image" />
                    </div>
                    <div className="content-item">
                        <div className="text">
                            <h4 className="heading">LINE指導</h4>
                            <p className="heading">LINE指導では、成果報告・食事写真の提出をして頂きます。</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
);

export default Service;
