import React from 'react';
import { Link } from 'react-router-dom';

import '../App.css';
import '../css/Sendform.css';

const Sendform = () => {
    return (
        <div className="container">
            <h2>お問い合わせフォームが送信されました。</h2>
            <Link to="/" className="button">ホームに戻る</Link>
        </div>
    );
};

export default Sendform;