import React from 'react';
import '../App.css';
import '../css/Terms.css';

const Terms = () => (
    <div>
        <h2>利用規約について</h2><br />
        <div id="pickup" className="wrapper">
            <div className="consent">
                <p>
                    NET-FIT　サービス同意書<br /><br />
                    私たちのフィットネスサービスを利用していただき、誠にありがとうございます。<br />
                    この同意書には、私たちのサービスを利用する際に遵守すべきルールと規定が記載されています。<br /><br />
                    必ず最後までお読みいただき、理解したうえで同意していただくようお願いいたします。
                </p>
            </div>
            <ol>
                <li>フィットネスサービスの内容</li>
                <p>当フィットネスサービスは、健康とウェルネスを目的とした様々なプログラムや活動を提供しています。<br />
                    これには、フィットネスクラス、トレーニングセッション、アドバイス、栄養情報などが含まれます。</p>
                <li>健康上の注意事項</li>
                <p>フィットネスサービスを利用する前に、健康状態に関する注意事項を理解してください。<br />
                    あなたの健康状態がフィットネス活動に適していない場合、医師と相談することを強くお勧めします。
                    <br />当社のサービスを利用することで発生する健康上の問題については、当社は一切の責任を負いかねます。</p>
                <li>個人情報の取り扱い</li>
                <p>私たちは、お客様の個人情報を慎重に取り扱います。<br />
                    個人情報の収集、使用、および保管については、当社のプライバシーポリシーに準拠します。<br />
                    詳細については、当社のウェブサイトで公開されているプライバシーポリシーをご確認ください。</p>
                <li>サービス利用の責任</li>
                <p>フィットネスサービスを利用するにあたり、あなたは他の参加者やスタッフに対して適切な配慮と礼儀を持って行動することが求められます。<br />
                    暴力行為、嫌がらせ、不適切な言動などは禁止されています。<br />
                    違反があった場合、当社はお客様のサービス利用を中止する権利を保持します。</p>
                <li>サービス内での指導に限る</li>
                <p>当フィットネスサービスは、指導者やトレーナーの監督のもとで提供されるものに限ります。<br />
                    サービス内での指導外の活動、プログラム、またはアクティビティを自己判断で行った場合、その結果に対して当社は一切の責任を負いません。</p>
                <li>怪我や健康問題への対応</li>
                <p>フィットネスサービスの利用中に怪我、体調不良、またはその他の健康上の問題が発生した場合、直ちにスタッフに報告し、<br />
                    適切な対応を受けるようお願いいたします。<br /><br />
                    ただし、指導者やスタッフの指示に従わない行為や、安全対策を無視した行動によって発生した怪我や健康問題については、当社は責任を負いません。</p>
                <li>個人の責任</li>
                <p>フィットネスサービスを利用するには、自己責任が求められます。<br />
                    サービスの提供者は、参加者の健康状態や体力について完全に把握することは困難です。<br />
                    したがって、各参加者は自身の体力や健康状態を理解し、適切な範囲内で活動する責任を負います。</p>
                <li>免責事項</li>
                <p>当フィットネスサービスを利用することによって生じたいかなる損害や怪我、健康問題に対しても責任を負いません。<br />
                    これには、直接または間接的な損害、経済的な損失、身体的な傷害、精神的苦痛、第三者との紛争などが含まれます。</p>
                <li>サービスの変更とキャンセル</li>
                <p>当サービスは、事前の通知なくフィットネスサービスの内容やスケジュールを変更することがあります。<br />
                    また、不測の事態により、サービスを中止する可能性もあります。その際は、可能な限り早急に通知いたします。</p>
            </ol>
            <div className="consent">
                <p>同意の確認</p>
                <p>
                    私は、上記の「サービス利用の責任」に関する内容を理解し、遵守することに同意します。<br />フィットネスサービスの提供者が指導する範囲内でサービスを利用し、自己の責任において行動することを理解します。<br />
                    また、当社が提供するサービス内での指導以外の行動については、当社が責任を負わないことを認識しています。
                </p>
            </div>
        </div>
    </div>
);

export default Terms;
