import React, { useState, useEffect } from 'react';
import '../css/Trainer.css';
import satomi from '../images/trainers/satomi.jpg';
import kazuo from '../images/trainers/kazuo.jpg';
import Naoki from '../images/trainers/Naoki-Matsumoto.jpg';
import jucy from '../images/trainers/jucy.jpg';
import hiroto from '../images/trainers/hiroto.jpg';
import hina from '../images/trainers/hina.jpg';

const Trainer = () => {
  const trainers = [
    {
      name: "福原里美",
      qualification: "JATI-ATI(日本機関)",
      achievements: "サマースタイルアワード2018年 2位",
      experience: "7年以上",
      personality: "厳しめ・ストイック",
      image: satomi,
    },
    {
      name: "高橋一雄",
      qualification: "NSCA-CPT(米国機関)",
      achievements: "ベストボディジャパン2019年 3位",
      experience: "3年以上",
      personality: "明るい・優しい",
      image: kazuo,
    },
    {
      name: "松本尚輝",
      qualification: "NSCA-CPT(米国機関)",
      achievements: "2021年マッスルゲート東京 優勝",
      experience: "6年以上 元ゴールドジム",
      personality: "神経質・ストイック",
      image: Naoki,
    },
    {
      name: "マークニック・ジェシー",
      qualification: "NESTA(米国機関)",
      achievements: "2017年日本マラソン 3位",
      experience: "9年以上",
      personality: "丁寧な指導・よく笑う",
      image: jucy,
    },
    {
      name: "海老原裕人",
      qualification: "JATI-ATI(日本機関)",
      achievements: "サマースタイルアワード2019年 優勝",
      experience: "5年以上",
      personality: "細かい指導・ストレートな性格",
      image: hiroto,
    },
    {
      name: "西原陽奈",
      qualification: "JATI-ATI(日本機関)",
      achievements: "サマースタイルアワード2020年 7位",
      experience: "3年以上",
      personality: "几帳面・真面目",
      image: hina,
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const changeSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % trainers.length);
  };

  useEffect(() => {
    const interval = setInterval(changeSlide, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <h2>トレーナーメンバー</h2>
      <div className="trainer-container">
        <br />

        <div id="pickup" className="wrapper">
          {trainers.map((trainer, index) => (
            <article key={index} style={{ display: index === currentSlide ? 'block' : 'none' }}>
              <img src={trainer.image} alt={trainer.name} className="article-image" />
              <p className="article-text">
                名前 : {trainer.name}<br />
                資格 : {trainer.qualification}<br />
                競技 : {trainer.achievements}<br />
                歴 : {trainer.experience}<br />
                性格 : {trainer.personality}</p><br />
            </article>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Trainer;
