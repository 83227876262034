import '../App.css';
import '../css/Price.css';

const Price = () => {
    return (
        <div>
            <h2>料金メニュー</h2><br/>

            {/* admission fee */}
            <h3>入会金</h3>
            <table>
                <thead>
                    <tr>
                        <th>入会期間</th>
                        <th>入会金</th>
                        <th>割引額</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1ヶ月</td>
                        <td>¥25000</td>
                        <td>¥0</td>
                    </tr>
                    <tr>
                        <td>2ヶ月</td>
                        <td>¥20000</td>
                        <td>¥5000</td>
                    </tr>
                    <tr>
                        <td>3ヶ月</td>
                        <td>¥15000</td>
                        <td>¥10000</td>
                    </tr>
                    <tr>
                        <td>4ヶ月</td>
                        <td>¥10000</td>
                        <td>¥15000</td>
                    </tr>
                    <tr>
                        <td>5ヶ月</td>
                        <td>¥5000</td>
                        <td>¥20000</td>
                    </tr>
                    <tr>
                        <td>6ヶ月</td>
                        <td>¥0</td>
                        <td>¥25000</td>
                    </tr>
                </tbody>
            </table>

            {/* number of times fee */}
            <h3>オンラインセッション</h3>
            <table>
                <thead>
                    <tr>
                        <th>時間</th>
                        <th>料金</th>
                        <th>備考</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>30分</td>
                        <td>¥2000</td>
                        <td>とにかく運動を始めたい方向け</td>
                    </tr>
                    <tr>
                        <td>45分</td>
                        <td>¥3000</td>
                        <td>運動に慣れている&慣れてきた方向け</td>
                    </tr>
                    <tr>
                        <td>1時間</td>
                        <td>¥5000</td>
                        <td>普通にトレーニングしたい方向け</td>
                    </tr>
                    <tr>
                        <td>1時間15分</td>
                        <td>¥6000</td>
                        <td>少し多めにトレーニングしたい方向け</td>
                    </tr>
                    <tr>
                        <td>1時間30分</td>
                        <td>¥7000</td>
                        <td>上半身&下半身共に追い込みたい方向け</td>
                    </tr>
                    <tr>
                        <td>2時間</td>
                        <td>¥0</td>
                        <td>1日でガッツリ追い込みたい方向け</td>
                    </tr>
                </tbody>
            </table>

             {/* number of times fee */}
             <h3>レッスン教材</h3>
            <table>
                <thead>
                    <tr>
                        <th>種別</th>
                        <th>料金</th>
                        <th>備考</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>テキスト</td>
                        <td>¥1500</td>
                        <td>低価格で始めたい方向け</td>
                    </tr>
                    <tr>
                        <td>動画</td>
                        <td>¥3000</td>
                        <td>レッスン時間が合わない方向け</td>
                    </tr>
                </tbody>
            </table>

             {/* number of times fee */}
             <h3>食事指導</h3>
            <table>
                <thead>
                    <tr>
                        <th>時間</th>
                        <th>料金</th>
                        <th>備考</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>30分</td>
                        <td>¥3000</td>
                        <td>食事を見直したい方向け</td>
                    </tr>
                    <tr>
                        <td>45分</td>
                        <td>¥40000</td>
                        <td>しっかりとカロリー計算をしたい方向け</td>
                    </tr>
                    <tr>
                        <td>1時間</td>
                        <td>¥5000</td>
                        <td>全てのカロリーを詳細把握したい方向け</td>
                    </tr>
                </tbody>
            </table>

             {/* number of times fee */}
             <h3>LINE指導</h3>
            <table>
                <thead>
                    <tr>
                        <th>日数</th>
                        <th>料金</th>
                        <th>備考</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>週1</td>
                        <td>¥3000</td>
                        <td>運動習慣をつけたい方向け</td>
                    </tr>
                    <tr>
                        <td>週3</td>
                        <td>¥5000</td>
                        <td>目標達成したい方向け</td>
                    </tr>
                    <tr>
                        <td>週5</td>
                        <td>¥10000</td>
                        <td>しっかりとしたフィードバックを受けたい方向け</td>
                    </tr>
                </tbody>
            </table><br /><br />
        </div>
    );
};

export default Price;