import { Link } from 'react-router-dom';
import '../css/Sendrequest.css';

const Sendrequest = () => {
    return (
        <div className="container">
            <h2>お申し込みフォームが送信されました。</h2>
            <Link to="/" className="button">ホームに戻る</Link>
        </div>
    );
};

export default Sendrequest;